import React from "react"
import Img from "gatsby-image"
import styles from "./about.module.less"

import Layout from "../components/Layout"

const AboutPage = ({ data }) => {
  const pageData = data.pageData.nodes[0]

  return (
    <Layout
      meta={{
        title: pageData.metaTitle,
        description: pageData.metaDescription.metaDescription,
        image: pageData.metaImage.localFile.publicURL,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div className="text-center">
              <h1 className={"page-title"}>{pageData.title}</h1>
              <div className={styles.about_image}>
                <Img
                  fluid={pageData.portraitImage.localFile.childImageSharp.fluid}
                />
              </div>
              <h2>{pageData.subtitle}</h2>
            </div>

            {pageData.description.description.split("\n").map((text, idx) => (
              <p key={idx}>{text}</p>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    mainImage: file(relativePath: { eq: "tamar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageData: allContentfulAboutPage {
      nodes {
        title
        portraitImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        subtitle
        description {
          description
        }

        metaTitle
        metaDescription {
          metaDescription
        }
        metaImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
